import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link, useNavigate } from 'react-router-dom';
import './ProductDetails.css';
import { Button, Carousel, Container  } from 'react-bootstrap';
import { FormattedNumber } from 'react-intl';
import AddToCartButton from './AddToCartButton';
import { useState } from 'react';
import { arrowLeftSVG, circularSVG, energySVG, organicSVG, arrowRightSVGCarousel, arrowLeftSVGCarousel } from './SVGs';
import { ColourPicker } from './ColourPicker';
import Sizing from './Sizing';
import MagnifyHover from './MagnifyHover';
import { addToCart } from '../contexts/ShopContext.js';
import { useShop } from '../contexts/ShopContext.js';
import { CustomProduct } from './CustomProducts';
import { useEffect } from 'react';
import { useStudio } from '../contexts/StudioContext.js';

export function ProductDetails(props) {
    const shopContext = useShop();
    const studioContext = useStudio();
    const product = props.product;
    const base = shopContext.shop.bases[product.base];

    const colours = Object.keys(product.colours || base.colours);

    const navigate = useNavigate();

    const [isTablet, setIsTablet] = useState(false);

    useEffect(() => {
        function checkTablet() {
            setIsTablet(window.innerWidth < 992); /* breakpoint large */
        }
        checkTablet();
        window.addEventListener('resize', checkTablet);
        return () => window.removeEventListener('resize', checkTablet);
    }, []);

    const [ colour, setColour ] = useState(props.colour || product.colour || colours[0]);
    const [ selected, setSelected ] = useState(0);
    const [ selectedPlain, setSelectedPlain ] = useState(0);

    const images = !product.thumbnail && product.colours[colour];

    const title = product.title || `My OilOnShirt - ${base.title}`;
    const productImages = product.images || base.images;

    const addProduct = {
        title,
        sizes: product.sizes || base.sizes,
    };

    const currencySymbol = "GBP";

    const handleAddToCart = (size) => addToCart(shopContext, product.id, colour, size);

    const handleCustomize = () => {
        const payload = product.thumbnail ? product : { base: product.base };
        studioContext.dispatch({ type: 'RESET', payload: payload });
        navigate('/studio');
    };

    return (
        <>
            <div className="ProductDetails-width">
                <Button variant="" as={Link} to="/collection/all" className="ProductDetails-allProducts">
                    {arrowLeftSVG} All Products / <span style={{fontSize:"calc(8px + 0.1vw)", color:"#21565a"}}>{title}</span>
                </Button>
                <Row>
                    <Col className='my-2' style={{padding:"2%"}}>
                        <Row className='my-2'>
                            <Col xs={12} lg={6} className='my-2'>
                                <Carousel interval={null} activeIndex={selected} onSelect={setSelected} variant="dark" indicators={null} nextIcon={arrowRightSVGCarousel} prevIcon={arrowLeftSVGCarousel}>
                                    {productImages.map(img =>  <Carousel.Item key={img}>
                                        <MagnifyHover image={img} imageAlt={title}/>
                                    </Carousel.Item>)}
                                </Carousel>
                            </Col>
                            { !isTablet &&
                            <Col lg={6} className="my-2 ProductDetails-align">
                                <MagnifyHover image={productImages[1]} imageAlt={title}/>
                            </Col>}
                        </Row>
                        <Row className='my-2'>
                            { !isTablet &&
                            <Col xs={12} lg={6} className="ProductDetails-align">
                                <MagnifyHover image={productImages[2]} imageAlt={title}/>
                            </Col>}
                            <Col xs={12} lg={6}>
                                {product.thumbnail ?
                                    <CustomProduct product={product} colour={colour} /> :
                                    <Carousel interval={null} activeIndex={selectedPlain} onSelect={setSelectedPlain} variant="dark" indicators={null} nextIcon={arrowRightSVGCarousel} prevIcon={arrowLeftSVGCarousel}>
                                        {images.map(img =>
                                            <Carousel.Item key={img}>
                                                <MagnifyHover image={img} imageAlt={title}/>
                                            </Carousel.Item>
                                        )}
                                    </Carousel>
                                }
                                { isTablet &&
                                <>
                                    <ColourPicker colours={colours} onSelect={setColour} selected={colour}/>
                                    <Sizing />
                                    <hr className="hr-light my-4 w-100" />
                                    <Row>
                                        <Col>
                                            <AddToCartButton product={addProduct} colour={colour} onSave={handleAddToCart} />
                                        </Col>
                                        <Col>
                                            <Button variant="" className='mx-2 my-2 buttonStyle-accent' onClick={handleCustomize}>Customise</Button>
                                        </Col>
                                    </Row>
                                </>
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={4} className='my-3' style={{padding:"2%"}}>
                        <div className='ProductDetails-title'>
                            {title} | <FormattedNumber value={product.price} style="currency" currencyDisplay="symbol" currency={currencySymbol}/>
                        </div>
                        <hr className="hr-light my-4 w-100" />
                        <div className='ProductDetails-text'>
                            <div>{product.desc || base.desc}</div>
                            <div style={{height:"20px"}} />
                            <hr className="hr-light my-4 w-100" />
                            <Row>
                                <Button variant="" size="sm" className="my-2 buttonStyle-brown" as={Link} to="/sustainability">Sustainability</Button>
                            </Row>
                            <Row>
                                <Col>
                                    <div style={{margin:"4%"}}>{organicSVG} Organic</div>
                                    <div style={{margin:"4%"}}>{energySVG} Renewables</div>
                                    <div style={{margin:"4%"}}>{circularSVG} Circular</div>
                                </Col>
                                <Col style={{textAlign:"left"}}>
                                    <div style={{margin:"4% 0%"}}>Manufacturer: <br /> Teemill Tech Ltd. | Organic Cotton Farming and Sewing in India, Printing, UK</div>
                                </Col>
                            </Row>
                            <hr className="hr-light my-4 w-100" />
                        </div>
                        { !isTablet &&
                        <>
                            <ColourPicker colours={colours} onSelect={setColour} selected={colour}/>
                            <Sizing />
                            <hr className="hr-light my-4 w-100" />
                            <Row>
                                <Col>
                                    <AddToCartButton product={addProduct} colour={colour} onSave={handleAddToCart} />
                                </Col>
                                <Col>
                                    <Button variant="" className='mx-2 my-2 buttonStyle-accent' onClick={handleCustomize}>Customise</Button>
                                </Col>
                            </Row>
                        </>}
                    </Col>
                </Row>
                <Button variant="" as={Link} to="/collection/all" className="ProductDetails-allProducts">
                    {arrowLeftSVG} All Products / <span style={{fontSize:"calc(8px + 0.1vw)", color:"#21565a"}}>{title}</span>
                </Button>
            </div>
        </>
    )
}