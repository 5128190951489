import React from "react";
import Payment from './Payment';
import AddressSummary from "./AddressSummary";
import { Col, Container, Row } from "react-bootstrap";
import OrderSummary from "./OrderSummary";

export default function CheckoutPayment(props) {
    const order = props.order;
    const {cart, details} = order;
    const {currency, shipping} = details;

    const cartTotal = cart.subtotal * currency.rate;
    const shippingCost = shipping.cost * currency.rate;
    const price = { subtotal: cartTotal, shipping: shippingCost };

    const valid = details.price === Math.floor(100*(cartTotal + shippingCost));

    return (
        <>
            {valid ? <>
                <Row style={{width: "80vw", margin: "auto"}}>
                    <Col md={6} xs={12}>
                        <AddressSummary order={details}/>
                        <OrderSummary cart={cart} currency={currency} price={price}/>
                    </Col>
                    <Col md={6}>
                        <Payment clientSecret={order.payment}/>
                    </Col>
                </Row>
            </> : <p>Sorry, something went wrong. Please try again later.</p>}
        </>
    );
}