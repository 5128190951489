import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { Row, Col } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { infoSVG, arrowRightSVG, boxSVG, arrowDownSVG } from './SVGs';
import EMailSignup from './EmailSignup';

export default function NavigationOffcanvas() {
const [showOffCanvas, setShowOffCanvas] = useState(false);

const handleClose = () => setShowOffCanvas(false);
const handleShowOffCanvas = () => setShowOffCanvas(true);

const [isMobile, setIsMobile] = useState(false);

useEffect(() => {
	function checkMobile() {
		setIsMobile(window.innerWidth < 768);
	}
	checkMobile();
	window.addEventListener('resize', checkMobile);
	return () => window.removeEventListener('resize', checkMobile);
}, []);

return (
	<>
		<Button variant="" onClick={handleShowOffCanvas} className="my-1 Navigation-tagline colorise">{infoSVG} Delivery | FREE UK SHIPPING from £50 {arrowDownSVG}</Button>
			<Offcanvas show={showOffCanvas} onHide={handleClose} placement='bottom' style={{height:"100vh"}} className="NavigationOffCanvas NavigationOffCanvas-font Navigation-colorise">
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>
						<div>Delivery | Returns & Exchanges</div>
						<div className="DeliveryModal-h1">{boxSVG} FREE UK shipping for orders from £50</div>
					</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Row style={ isMobile ? {fontSize:"calc(10px + 0.8vw)", marginTop:"20px", width:"80vw"} : {fontSize:"calc(10px + 0.5vw)", marginTop:"20px", width:"85vw"}}>
						<Col className='mx-4 my-2' xl={4}>
							<h1 style={{textDecoration:"underline"}}>Delivery</h1>
							<div style={{marginTop:"20px"}}>Most orders are printed by our manufacturing partner Teemill on the same working day.</div>
							<hr />
							<h2 style={{color:"#21565a"}}>Shipping Options</h2>
							<table>
								<caption>
									<p style={{color:"#21565a"}}>*orders placed before 10 am GMT</p>
									<p>EU Tracked & Signed £15.00</p>
									<p>International Tracked & Signed £15.50</p>
								</caption>
								<tbody style={{backgroundColor:"#dcd6f760"}}>
									<tr>
										<td>UK Standard £4.00</td>
										<td>2-3 days</td>
									</tr>
									<tr>
										<td>UK 1st Class £6.00</td>
										<td>1-2 days</td>
									</tr>
									<tr style={{color:"#21565a"}}>
										<td>UK Priority £9.50 *</td>
										<td>UK next day</td>
									</tr>
									<tr>
										<td>EU Standard £6.00</td>
										<td>3-5 days</td>
									</tr>
									<tr>
										<td>International £7.00</td>
										<td>6-7 days</td>
									</tr>
								</tbody>
							</table>
							<hr />
							<p>Once your order is being processed, you'll receive an email confirmation including an estimated delivery date. Public, bank or international holidays may affect shipping times.</p>
							<hr />
							<h2 style={{color:"#21565a"}}>Shipping Countries</h2>
							<p>We deliver to the UK, most EU countries and the US. You can see the full list of countries that we ship to at checkout.</p>
						</Col>
						<Col className='mx-4 my-2' xl={4}>
							<h1 style={{textDecoration:"underline"}}>Returns & Exchanges</h1>
							<div style={{marginTop:"20px"}}>We want you to be happy, so if in doubt, please reach out to us any time at:</div>
							<div style={{marginTop:"20px", color:"#21565a"}}>HELLO@OILONSHIRT.COM including your order ID, and we will process your request as quickly as possible.</div>
							<hr />
							<h2 style={{color:"#21565a", marginTop:"20px"}}>Plain Products</h2>
							<div style={{marginTop:"5px"}}>FREE UK returns. You can exchange or return your item at any time, up until the later of 14 days after you receive the products or 28 days after we dispatch them for delivery to you.</div>
							<h2 style={{color:"#21565a", marginTop:"20px"}}>Custom Products</h2>
							<div style={{marginTop:"5px"}}>Unless they are defective, or not according to our high quality standard, we cannot accept returns of custom products, sample orders, or any products that have been washed or worn.</div>
						</Col>
						<Col className='mx-4 my-2'>
							<h1 style={{textDecoration:"underline"}}>Sign Up</h1>
							<div style={{marginTop:"20px"}}>We alternately run Free UK shipping, tree planting and plastic recovery weekends. Sign up to be notified in advance.</div>
							<EMailSignup />
						</Col>
					</Row>
				</Offcanvas.Body>
			</Offcanvas>
	</>
);
}