import React from "react";
import AddressForm from './AddressForm';
import { useState } from "react";
import { useLocale } from "../contexts/LocaleContext";
import Col from "react-bootstrap/esm/Col";
import axios from 'axios';
import OrderSummary from "./OrderSummary";
import { useEffect } from "react";
import { useShop, summarizeCartItems } from '../contexts/ShopContext';


const DEFAULT_SHIPPING = "UK Standard Delivery";


export default function CheckoutAddress(props) {
    const { shop } = useShop();
    const { currency } = useLocale();

    const order = props.order;
    const details = order.details;

    const [shipping, setShipping] = useState(details ? details.shipping.method : DEFAULT_SHIPPING);

    const cartTotal = order.cart.subtotal * currency.rate;
    const freeShipping = shop.freeShipping && cartTotal >= shop.freeShipping.threshold[currency.symbol];
    const shippingCost = shop.shipping && freeShipping ? {...shop.shipping, ...shop.freeShipping.methods} : shop.shipping;

    const price = shippingCost && { subtotal: cartTotal, shipping: shippingCost[shipping] };

    const handleAddress = (address) => axios({
        method: "post",
        withCredentials: true,
        url: `${process.env.REACT_APP_BACKEND_URL}/orders/${order.id}/details`,
        data: JSON.stringify({address, shipping, currency}),
        headers: { "Content-Type": "application/json" },
    })
        .then(res => props.onUpdate(res.data))
        .catch((res) => console.log(res));

    useEffect(() => {
        window.dataLayer.push({
            'event': 'StartCheckout',
            'cart_total': cartTotal,
        });
    }, []);

    return (
        <>
            <Col md={12} lg={8}>
                <AddressForm onAddress={handleAddress} details={details}
                                shipping={{ method: shipping, cost: shippingCost }} setShipping={setShipping}/>
            </Col>
            <Col>
                <OrderSummary cart={order.cart} currency={currency} price={price}/>
            </Col>
        </>
    );
}