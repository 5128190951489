import React from "react";
import { ProductCardOrder } from './ProductCard';
import './OrderSummary.css';
import {FormattedNumber} from 'react-intl';


export default function OrderSummary(props) {
    const { cart, currency, price } = props;

    const products = cart.custom.concat(cart.catalog);
    const getProduct = (item) => products.find((p) => p.id == item.id);

    const itemId = (item) => item.id + item.colour + item.size;

    return (
        <div className="my-2 AddressSummary-frame">
            <h1 className="my-2 OrderSummary-headline">ORDER SUMMARY</h1>
            {price ? <>
                <div className="OrderSummary-text">
                    <p>Subtotal: <FormattedNumber
                        value={price.subtotal}
                        style="currency"
                        currencyDisplay="symbol"
                        currency={currency.symbol}/></p>
                    <p>Shipping: <FormattedNumber
                        value={price.shipping}
                        style="currency"
                        currencyDisplay="symbol"
                        currency={currency.symbol}/></p>
                    <hr />
                    <p>Total: <FormattedNumber
                        value={price.subtotal + price.shipping}
                        style="currency"
                        currencyDisplay="symbol"
                        currency={currency.symbol}/></p>
                </div>
                <br />
                <h2 className="my-2 OrderSummary-headline">Your Products</h2>
                {cart.items.map((p) => <div key={itemId(p)} xxl={6}>
                    <ProductCardOrder item={p} product={getProduct(p)} />
                </div>)}

            </> : <p>Loading</p>}
        </div>
    )
}