import React from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { Button } from 'react-bootstrap';
import './Favourites.css';
import { useShop } from '../contexts/ShopContext';
import Container from 'react-bootstrap/esm/Container';
import { Link } from 'react-router-dom';
import { BASES_BY_ID, canvas_url } from '../const';
import TShirt from '@oos/core/components/TShirt';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { useStudio } from '../contexts/StudioContext.js';
import { useDesigns } from '@oos/core/components/DesignsContext';
import { stackSVG, magicSVG, bagSVG } from './SVGs';


export default function CustomProducts(props) {
    const { shop } = useShop();
    const products = shop.custom;

    return (
        <Container fluid className="Favourites-width pageHeight">
            {products ?
                (
                    products.length ? (
                        <Row>
                            {products.map((p) =>
                                <Col xs={12} sm={6} lg={4} key={p.id}>
                                    <CustomProductCard product={p} onSelect={props.onSelect} />
                                </Col>)}
                        </Row>
                    ) :
                    <>
                        <p className='Favourites-text'>You have no custom products yet.</p>
                        <Link to="/studio/AiTool">
                            <button
                                style={{width: "22vw"}}
                                className='my-2 mx-2 px-2 buttonStyle-lavender ImageUploadCloud Home-title'>
                                <span style={{fontSize: "3vw", fontWeight: "100"}}>Start Creating</span>
                            </button>
                        </Link>
                    </>
                ) : <p className='Favourites-text'>Loading</p>
            }
        </Container>
    )
}


export function CustomProduct(props) {
    const product = props.product;
    const colour = props.colour || product.colour;
    const canvasImage = canvas_url(product.base, colour, props.back);
    const canvas = BASES_BY_ID[product.base].front;

    return (
        <Link to={`/product/${product.id}?colour=${product.colour}`}>
            <TShirt image={canvasImage} canvas={canvas}>
                <img src={product.thumbnail} style={{ width: "100%" }}/>
            </TShirt>
        </Link>
    )
};


function CustomProductCard (props) {
    const studioContext = useStudio();
    const designsContext = useDesigns();
    const shopContext = useShop();
    const product = props.product;
    // console.log(product);

    const design = props.design;

    // const backPrint = product.front.length == 0 && product.back.length > 0;

    const [showAlert, setShowAlert] = React.useState(false);

    const removeFromStudio = () => (studioContext.studio.id == product.id) && studioContext.dispatch(
        { type: 'SET_ID', payload: null }
    );

    const removeProduct = () => axios({
        method: "delete",
        withCredentials: true,
        url: `${process.env.REACT_APP_BACKEND_URL}/products/${product.id}`,
    })
        .then((res) => {
            shopContext.dispatch({ type: 'REMOVE_PRODUCT', payload: res.data });
        })
        .catch((res) => console.log(res));

    const handleRemove = () => {
        removeFromStudio();
        return removeProduct();
    }

    const checkInCart = (pid) => shopContext.shop.cart.findIndex((i) => i.id == pid) >= 0;

    const handleSelect = () => {
        studioContext.dispatch({ type: 'RESET', payload: product });
        props.onSelect();
    };

    return (
        <>
            <Container fluid className='my-2 ProductCard-tabs'>
                <Col className='mb-4'>
                    <div className='DesignCard-Container'>
                        <CustomProduct product={product} />
                        <div className='DesignCard-Overlay'>
                            <Card.Body>
                                {/* <Card.Title className='ProductCard-text'>{product.title}</Card.Title> */}
                                <Button
                                    variant=''
                                    style={{width: "45%", maxHeight: "15vw", fontSize: "calc(10px + 0.2vw)"}}
                                    className='mx-1 p-1 my-1 buttonStyle-brown cursorPointer'
                                    onClick={handleSelect}>
                                    {magicSVG} Customise
                                </Button>
                                <Button
                                    as={Link}
                                    to={`/product/${product.id}?colour=${product.colour}`}
                                    variant=''
                                    style={{width: "45%", maxHeight: "15vw", fontSize: "calc(10px + 0.2vw)"}}
                                    className='mx-1 p-1 my-1 buttonStyle-brown cursorPointer'>
                                    {bagSVG} View
                                </Button>
                                <br />
                                {!showAlert && <Button onClick={() => setShowAlert(true)} variant="" className='my-1 buttonStyle-lavender' style={{fontSize: "calc(10px + 0.2vw)"}}>Delete</Button>}
                            </Card.Body>

                            <Alert show={showAlert} variant="danger" className='my-3 mx-2 ProductAlert'>
                                <Alert.Heading>Are you sure?</Alert.Heading>
                                {checkInCart(product.id) && <p className='ProductCard-text'>
                                    This will also delete from your cart.</p>}
                                <hr />
                                <div className="d-flex justify-content-center">
                                    <Button
                                        onClick={handleRemove}
                                        size="sm"
                                        variant='outline-danger'
                                        style={{fontSize: "calc(10px + 0.2vw"}}
                                        className='my-2 mx-1 buttonStyle-lavender'>
                                        Yes, delete.
                                    </Button>
                                    <Button
                                        onClick={() => setShowAlert(false)}
                                        size="sm"
                                        variant=""
                                        style={{fontSize: "calc(10px + 0.2vw"}}
                                        className='my-2 mx-1 buttonStyle-lavender'>
                                        Cancel
                                    </Button>
                                </div>
                            </Alert>
                        </div>
                    </div>
                </Col>
            </Container>
        </>
    )
}