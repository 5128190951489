import { Link } from 'react-router-dom';
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Card } from 'react-bootstrap';
import './About.css';
import Image from "react-bootstrap/esm/Image";
import Button from 'react-bootstrap/esm/Button';
import HomeArt from '../HomeArt';
import EMailSignup from '../EmailSignup';
import BlogBanner from './BlogBanner';
import { Typewriter } from '../Typewriter';

export default function HowTo() {

    return (
        <>
            <div className="Sustainability-tabs-overlay Sustainability-bg-col" style={{backgroundColor: "#dcd6f780"}}>
                <div style={{height:"2vh"}} />
                <h1 className="Sustainability-title">How to design my AI print shirt</h1>
                <div style={{height:"1vh"}} />
                <h2 className="Sustainability-heading-sm">Text to image Ai art clothing</h2>
                <Button style={{width:"30vw"}} variant="" className="my-3 buttonStyle-accent" as={Link} to="/studio/AiTool">Design Now</Button>
            </div>

            <BlogBanner image="/about_ai_cotton_banner.webp" title="Ai Art for Custom Print Clothes" link="/studio"/>

            <div className='Home-title my-4'>How To Guide</div>
            <div className="About-heading-sm">01100001 Ai 01110010 01110100 Art</div>
            <div style={{height: "calc(15px + 5vh)"}} />

            <h1 className='About-heading'>Design your own print clothes</h1>
            <h2 className='About-heading-sm'>with Text to Image Ai</h2>

            <div style={{height:"5vh"}}/>
            <section id="HomeSection3" style={{backgroundImage: `url(${"/sweater_banner.png"}`}}>
                <Container className="my-4 Home-background-color">
                    <Row className="Home-column-height-l align-items-center">
                        <Col>
                            <div className="">
                                <div style={{height:"3vh"}} />
                                <Typewriter />
                                <div style={{height:"2vh"}} />
                                <div className="Home-text">Wear Your Story!</div>
                                <Button size="lg" variant='' className="buttonStyle-accent" as={Link} to="/studio/AiTool">Design Now</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <h2 className='About-heading-sm Home-title'>Still wearing off the shelf?</h2>
            <h2 className='About-heading-sm'>Wear what you care about.</h2>
            <p className="About-text">lorem ipsum</p>

            <div className="About-tabs">
                <Button size="lg" variant='' className="mb-5 buttonStyle-accent" as={Link} to="/studio/AiTool">Try it Now</Button>

                <HomeArt />

                <Link to="/studio">
                    <Card style={{border:"none", background:"transparent"}}>
                        <Card.ImgOverlay className='About-text'>
                            <Card.Title>OilOnShirt</Card.Title>
                            <Card.Text className="display-6">Line Art</Card.Text>
                            <Card.Text>Organic Cotton Sweater</Card.Text>
                        </Card.ImgOverlay>
                        <Image src="/banner_product_women_sweater.jpeg" className="my-2 About-tabs-img" fluid></Image>
                    </Card>
                </Link>
            </div>

            <div className="About-tabs">
                <h1 className="display-5 my-3">JOIN THE AI ART COMMUNITY</h1>

                <Row>
                    <Col md={6}>
                        <div className='About-text' style={{marginBottom:"0px"}}>We continuously enhance our AI design tool for you. As we are in development stage, new stuff is happening on a daily basis. Want to get notified about the big leaps? Hit the subscribe button now.</div>
                        <EMailSignup />
                    </Col>
                    <Col>
                        <Image src="/samples_11.webp" style={{width:"50%"}}className="About-tabs-img" fluid></Image>
                    </Col>
                </Row>
            </div>
        </>
    )
}