import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import './Blog.css';

export default function BlogContent () {

    const POSTS = [
        {
            title: "Sustainability",
            subtitle: "GOTS certified organic cotton | Circular by Design",
            image: "sustainability_sustainability.webp",
            slug: "sustainability",
        },
        {
            title: "Why buy ORGANIC Cotton?",
            subtitle: "Less water, free from pesticides and harmful chemicals",
            image: "sustainability_circular_shirt.webp",
            slug: "why-buy-organic-cotton-clothing",
        },
        {
            title: "Ai infused organic Clothes",
            subtitle: "Organic Cotton meets Ai Art",
            image: "about_ai_cotton1.webp",
            slug: "about-ai-print-on-demand",
        },
        // {
        //     title: "How to design my AI print shirt",
        //     subtitle: "Text to image Ai art clothing",
        //     image: "about_ai_cotton_banner.webp",
        //     slug: "how-to-design-my-own-ai-print-tshirt",
        // } TODO Finish and publish
    ];

    const mapBlogContent = (post) =>
        <Col xs={12} md={6} className="my-2" key={post.slug}>
            <section id="HomeSection2" style={{backgroundImage: `url(${`"/${post.image}"`}`}}>
                <Container fluid>
                    <Row className="Home-column-height-m align-items-center">
                        <Col xs={{span: 8, offset: 2}}>
                            <div className="Blog-overlay About-bg-col">
                                <div style={{height:"2vh"}} />
                                <h1 className="Blog-title">{post.title}</h1>
                                <div style={{height:"1vh"}} />
                                <h2 className="Blog-subtitle">{post.subtitle}</h2>
                                <Button variant="" style={{width:"50%"}} className="my-3 buttonStyle-accent" as={Link} to={`/blog/${post.slug}`}>Read Now</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Col>;

    return (
        <>
            <div style={{height:"2vw"}} />
            <Row style={{width:"90vw", margin:"auto"}}>
                {POSTS.map(mapBlogContent)}
            </Row>
        </>
    )
}