import './Typewriter.css';
import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import "./Typewriter.css";

export default function TextEffect() {

    // const TYPETEXT = [
    //     {text: "Your Design", id: "2"},
    // ]

    return (
        <>
            <div className="Typewriter loading02">
                <span>y</span>
                <span>o</span>
                <span>u</span>
                <span>r</span>
                <div style={{height: "10px"}} />
                <span>d</span>
                <span>e</span>
                <span>s</span>
                <span>i</span>
                <span>g</span>
                <span>n</span>
                {/* {TYPETEXT.map((txt) => (
                    <Col key={txt.id}>
                        <div className="Typewriter-text">{txt.text}</div>
                    </Col>
                ))} */}
            </div>
        </>
    )
}

export function TypewriterText() {
    return (
        <div className="Typewriter-text">
            <div style={{width: "30vw"}}>SUPERWOMAN FLIES THROUGH NEW YORK CITY CARRYING A CAT THAT SHE JUST RESCUED FROM A ROOF</div>
        </div>
    )
}


export function Typewriter() {
    const [textIndex, setTextIndex] = useState(0);
    const [text, setText] = useState('');
    const [image, setImage] = useState('');

    const images = [
        { text: 'Cute cat face surrounded by flowers', image: '/samples_1.webp' },
        { text: "Elegant woman's head seen from the side wearing a hat", image: '/samples_4.webp' },
        { text: 'Sitting cat cleaning its paw', image: '/samples_7.webp' },
        { text: 'Yosemite mountain', image: '/samples_10.webp' },
        { text: "Portrait of a bull with colourful feathers", image: '/samples_11.webp' },
    ];

    const typeText = (textToType) => {
        let index = -1;
        const intervalId = setInterval(() => {
        index++;
        setText((prevText) => {
        if (index < textToType.length) {
            return prevText + textToType[index];
        }
        return prevText;
            });
            if (index === textToType.length) {
                clearInterval(intervalId);
                setImage(images[textIndex].image);
            }
        }, 100);
        return () => clearInterval(intervalId);
    };

    useEffect(() => {
        const textToType = images[textIndex].text;
        setText('');
        return typeText(textToType);  // clean up inside
    }, [textIndex]);

    useEffect(() => {
        const intervalId = setInterval(() => {
        setTextIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 6000);

        return () => clearInterval(intervalId);
        }, []);

    return (
        <Row>
            <Col>
                <div className="Typewriter-text">{text}
                <span className="Typewriter-cursor Typewriter-text">...|</span></div>
            </Col>
            <Col xs={6}>
                { image ? <img src={image} alt="Typewriter" className="Typewriter-img"/> :
                <img src="logo.png" className="Typewriter-img" /> }
            </Col>
            <Col />
        </Row>
    );
};